import {
  ContactFlagType,
  ContactFlagTypes,
} from "features/entity/entity.interfaces";
import useLocalStorage from "utils/hooks/useLocalStorage";
import { IMailbox } from "features/message/message.interface";

export const defaultTrackOptions = {
  mailboxes: [],
  showInternals: false,
  showPersonalRelationships: true,
  showPersonalEmailProviders: false,
  showServiceProviders: false,
};

export type trackOptionsType = typeof defaultTrackOptions;

export const trackSearchParams = (
  searchParams: URLSearchParams,
  trackOptions?: trackOptionsType
) => {
  if (!trackOptions) return searchParams;

  if (trackOptions?.mailboxes?.length) {
    searchParams.append("mailboxes", trackOptions.mailboxes.join(","));
  }
  searchParams.append(
    "internal_external_filter",
    trackOptions?.showInternals ? "both" : "only-external"
  );

  const flags: ("unflagged" | ContactFlagType)[] =
    trackOptions?.showPersonalRelationships
      ? ["unflagged", ...ContactFlagTypes]
      : [
          "unflagged",
          ...ContactFlagTypes.filter(
            (ContactFlagType) => ContactFlagType !== "personal_contact"
          ),
        ];

  flags.forEach((flag) => {
    searchParams.append("contact_flag_filter", flag);
  });

  searchParams.append(
    "email_provider_filter",
    trackOptions?.showPersonalEmailProviders ? "both" : "only-company"
  );

  searchParams.append(
    "service_provider_filter",
    trackOptions?.showServiceProviders
      ? "with-service-providers"
      : "without-service-providers"
  );

  return searchParams;
};

export const useTrackFilterOptions = (mailboxes?: IMailbox[]) => {
  const [trackOptions, setTrackOptions] = useLocalStorage("track-options", {
    defaultTrackOptions,
  });
  if (trackOptions["showPersonalRelationships"] === undefined) {
    setTrackOptions({ ...trackOptions, showPersonalRelationships: true });
  }
  if (trackOptions["showServiceProviders"] === undefined) {
    setTrackOptions({ ...trackOptions, showServiceProviders: false });
  }
  if (!trackOptions["mailboxes"] && mailboxes) {
    setTrackOptions({
      ...trackOptions,
      mailboxes: mailboxes
        .filter(({ primary }) => primary)
        .map(({ email }) => email),
    });
  }
  return [trackOptions, setTrackOptions];
};
