import classnames from "classnames";
import { createPortal } from "react-dom";

import useKeyPressed from "utils/hooks/useKeyPressed";

const getOrCreateRootElement = (id: string): HTMLElement => {
  let root = document.getElementById(id);
  if (!root) {
    root = document.createElement("div");
    root.setAttribute("id", id);
    document.body.appendChild(root);
  }
  return root;
};

type ModalLevel = "low" | "mid" | "high";

const Modal = ({
  children,
  visible,
  width = "small",
  height = "auto",
  usePadding = true,
  level = "low",
  id = "modal-container",
  onClose,
}: {
  children: any;
  visible: boolean;
  width?: "small" | "regular" | "large" | "xl";
  height?: "auto" | "regular" | "small" | "large" | "xl";
  usePadding?: boolean;
  level?: ModalLevel;
  id?: string;
  onClose: () => void;
}) => {
  const handleOnClose = (e: any) => {
    if (e.target.id === id) onClose();
  };

  useKeyPressed("Escape", onClose);
  const onKeyDown = (e: any) => {
    if (e.key === "Escape") onClose();
  };

  return createPortal(
    <div
      id={id}
      onClick={handleOnClose}
      onKeyDown={onKeyDown}
      className={classnames(
        "fixed inset-0 bg-navy bg-opacity-[8%] backdrop-blur-sm",
        {
          "z-50": level === "low",
          "z-[75]": level === "mid",
          "z-[100]": level === "high",
          hidden: !visible,
          visible,
        }
      )}
    >
      <div
        data-testid={`${id}-inner`}
        className={classnames(
          "relative mx-auto h-full w-full overflow-scroll border bg-white shadow sm:top-16 sm:max-h-[90vh] sm:w-96 sm:rounded-md",
          {
            "p-5": usePadding,
            "lg:w-modal-small": width === "small",
            "lg:w-modal-regular": width === "regular",
            "lg:w-modal-large": width === "large",
            "sm:w-full lg:w-modal-xl": width === "xl",
            "sm:h-auto": height === "auto",
            "sm:h-[480px]": height === "small",
            "sm:h-[600px]": height === "regular",
            "sm:h-[680px]": height === "large",
            "sm:top-0 sm:max-h-[100vh] lg:top-16 lg:max-h-[90vh]":
              height === "xl",
            "transition-default": visible,
          }
        )}
      >
        {children}
      </div>
    </div>,
    getOrCreateRootElement("portal")
  );
};

export default Modal;
